import { BaseModel } from './base.model';

export enum ActionType {
  LOGIN = 'login',
  APPROVE_DOCUMENT = 'approve_document',
  DECLINE_DOCUMENT = 'decline_document',
  ASSIGN_VEHICLE = 'assign_vehicle',
}

export class Action extends BaseModel {
  userId: string = '';
  performedBy: any;
  action: ActionType | '' = '';

  documentId?: string;

  constructor(data?: Partial<DocumentType>) {
    super();
    Object.assign(this, data);
  }
}
