import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Vehicle } from '@models';
import { UtilService } from '@services';
import {
  assignUserToVehicle,
  assignUserToVehicleFailed,
  assignUserToVehicleSuccess,
  fetchUsers,
  fetchVehicleById,
  selectUsersWithProfile,
  selectVehicleById,
  selectVehiclesFetchedAt
} from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  templateUrl: './vehicle-details.page.html',
  styleUrl: './vehicle-details.page.scss',
})
export class VehicleDetailsPage implements OnInit {
  $vehicle: Observable<Vehicle | undefined> | null = null;
  $users = this.store.select(selectUsersWithProfile);
  $vehiclesFetchedAt = this.store.select(selectVehiclesFetchedAt);

  userId = '';
  vehicleId = '';
  loading: boolean = false;
  error: string = '';

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private utils: UtilService
  ) {}

  public ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.vehicleId = params.get('vehicleId')!;

      this.store.dispatch(fetchVehicleById({ vehicleId: this.vehicleId }));
      this.store.dispatch(fetchUsers());

      this.$vehicle = this.store.select(selectVehicleById(this.vehicleId));
    });
  }

  public async onAssignDriverClicked(): Promise<void> {
    if (!this.userId || !this.vehicleId) {
      this.error = 'Please select a driver to assign to the vehicle.';
      return;
    }
    this.error = '';

    this.loading = true;

    try {
      await this.utils.actionsToPromise(
        assignUserToVehicle({ vehicleId: this.vehicleId, userId: this.userId }),
        assignUserToVehicleSuccess,
        assignUserToVehicleFailed
      );
    } catch (err: any) {
      this.error = err.message;
    }

    this.loading = false;
  }
}
