import { createAction, props } from '@ngrx/store';

import { Document, User } from '@models';

export const fetchUsers = createAction('[User] Fetch Users Initiated');
export const fetchUsersSuccess = createAction(
  '[User] Fetch Users Success',
  props<{ users: User[] }>()
);
export const fetchUsersFailed = createAction(
  '[User] Fetch Users Failed',
  props<{ error: Error }>()
);

// fetch user by id
export const fetchUserById = createAction(
  '[User] Fetch User By Id Initiated',
  props<{ userId: string }>()
);
export const fetchCurrentUser = createAction(
  '[User] Fetch Current User Initiated'
);
export const fetchUserByIdSuccess = createAction(
  '[User] Fetch User By Id Success',
  props<{ user: User }>()
);
export const fetchUserByIdFailed = createAction(
  '[User] Fetch User By Id Failed',
  props<{ error: Error }>()
);

// update user
export const updateUser = createAction(
  '[User] Update user',
  props<{ user: User }>()
);
export const updateUserSuccess = createAction(
  '[User] Update User Success',
  props<{ user: User }>()
);
export const updateUserFailed = createAction(
  '[User] Update User Failed',
  props<{ error: Error }>()
);

// approve user document
export const approveUserDocument = createAction(
  '[User] Approve User Document',
  props<{ document: Document }>()
);
export const approveUserDocumentSuccess = createAction(
  '[User] Approve User Document Success',
  props<{ document: Document }>()
);
export const approveUserDocumentFailed = createAction(
  '[User] Approve User Document Failed',
  props<{ error: Error }>()
);

// decline user document
export const declineUserDocument = createAction(
  '[User] Decline User Document',
  props<{ document: Document }>()
);
export const declineUserDocumentSuccess = createAction(
  '[User] Decline User Document Success',
  props<{ document: Document }>()
);
export const declineUserDocumentFailed = createAction(
  '[User] Decline User Document Failed',
  props<{ error: Error }>()
);
