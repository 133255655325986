<mat-card>
  <mat-card-content>
    <div class="d-flex justify-content-between">
      <mat-form-field appearance="outline" class="wider">
        <mat-label>Search</mat-label>
        <input
          matInput
          [(ngModel)]="search"
          (ngModelChange)="filterUsers()"
          placeholder="Search"
        />
        <mat-icon
          class="pointer"
          matSuffix
          (click)="search = ''; filterUsers()"
        >
          close
        </mat-icon>
      </mat-form-field>

      <div>
        @if(selectedStatus){
        <button mat-button (click)="clearFilter()" class="me-2">Clear</button>
        }
        <mat-form-field>
          <mat-label>User Status Filter</mat-label>
          <mat-select
            [(value)]="selectedStatus"
            (selectionChange)="filterByStatus()"
          >
            <mat-option *ngFor="let stat of status" [value]="stat.value"
              >{{ stat.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)"
    >
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{element.email}}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let element">
          {{element.createdAt | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
        <td mat-cell *matCellDef="let element">
          {{element.updatedAt | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let element">{{element.role}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          <div class="centered-vertically">
            <mat-icon [ngClass]="isUserApproved(element) ? 'green' : 'red'">
              {{isUserApproved(element) ? 'check_circle' : 'cancel'}}
            </mat-icon>
            <span class="small-light-grey">
              {{ this.getUserStatus(element) }}
            </span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns;"
        (click)="navToUserDetails(row.id)"
      ></tr>

      <tr *matNoDataRow>
        <td colspan="4">
          <div class="no-data-message">No users found.</div>
        </td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
