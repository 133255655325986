import { Routes } from '@angular/router';

import { LayoutComponent } from '@components';
import {
  ActionsPage,
  DashboardPage,
  DocumentTypePage,
  DocumentTypesPage,
  LoginPage,
  UserDetailsPage,
  UsersPage,
  VehicleDetailsPage,
  VehiclesPage,
} from '@pages';
import { AuthGuard } from '@services';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardPage,
        data: { breadcrumb: 'Dashboard' },
      },
      {
        path: 'users',
        component: UsersPage,
        data: { breadcrumb: 'Users' },
      },
      {
        path: 'users/:userId',
        component: UserDetailsPage,
        data: { breadcrumb: 'User Details' },
      },
      {
        path: 'document-types',
        component: DocumentTypesPage,
        data: { breadcrumb: 'Document Types' },
      },
      {
        path: 'document-types/:documentTypeId',
        component: DocumentTypePage,
        data: { breadcrumb: 'Document Type' },
      },
      {
        path: 'actions',
        component: ActionsPage,
        data: { breadcrumb: 'Actions' },
      },
      {
        path: 'vehicles',
        component: VehiclesPage,
        data: { breadcrumb: 'Motor Bikes' },
      },
      {
        path: 'vehicles/:vehicleId',
        component: VehicleDetailsPage,
        data: { breadcrumb: 'Motor Bike Details' },
      },
    ],
  },
  { path: 'auth/login', component: LoginPage },
  { path: '**', redirectTo: '' },
];
