import { Injectable } from '@angular/core';

import { Document } from '@models';
import { HttpService, IResponsePayload } from '@services';
import { environment } from '../../../environment/environment';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  constructor(private http: HttpService) {}

  async approveDocument(document: Document): Promise<Document> {
    const endpoint = `${environment.apiUrl}/admin/document/approve`;
    const response = await this.http.post<IResponsePayload>(endpoint, {
      documentId: document.id,
    });

    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  async declineDocument(document: Document): Promise<Document> {
    const endpoint = `${environment.apiUrl}/admin/document/decline`;
    const response = await this.http.post<IResponsePayload>(endpoint, {
      documentId: document.id,
    });

    if (!response.success) throw new Error(response.message);
    return response.data;
  }
}
