import { DocumentStatus } from '../enums/document-status';
import { BaseModel } from './base.model';
import { DocumentType } from './document-type';

export class Document extends BaseModel {
  userId: string = '';
  documentType: DocumentType = new DocumentType();
  downloadUrl: string = '';
  countryOfIssue: string = '';
  documentOption?: string;
  expiryDate?: Date;
  idNumber?: string;
  status: DocumentStatus = DocumentStatus.PENDING;

  constructor(data?: Partial<DocumentType>) {
    super();
    Object.assign(this, data);
  }
}
