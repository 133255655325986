import { createAction, props } from '@ngrx/store';

import { Action } from '@models';

// Fetch Actions:
export const fetchActions = createAction('[Action] Fetch Initiated');
export const fetchActionsSuccess = createAction(
  '[Action] Fetch Success',
  props<{ actions: Action[] }>()
);
export const fetchActionsFailed = createAction(
  '[Action] Fetch Failed',
  props<{ error: Error }>()
);
