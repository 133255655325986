<div class="login-container">
  <div class="container">
    <div class="card-holder mx-auto">
      <mat-card class="p-4 mt-3">
        <div class="logo">
          <img src="logo.png" />
        </div>

        <div class="mb-4">Sign in to your account</div>
        <form (ngSubmit)="onFormSubmit()" [formGroup]="form">
          <mat-form-field class="w-100">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email" />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password" />
          </mat-form-field>

          <div class="d-flex align-items-center justify-content-end mt-3">
            @if (loading) {
            <mat-progress-spinner
              class="me-2"
              mode="indeterminate"
              color="primary"
              diameter="30"
            />
            }
            <button
              mat-raised-button
              color="primary"
              type="submit"
              class="px-4"
              [disabled]="loading"
            >
              Login
            </button>
          </div>

          @if (error) {
          <app-alert (close)="error = null" class="mt-3" type="error">
            {{error}}
          </app-alert>
          }
        </form>
      </mat-card>
    </div>
  </div>
</div>
