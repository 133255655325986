import { createFeatureSelector, createSelector } from '@ngrx/store';

import { User } from '@models';
import { RouteState, selectRouteState, UserState } from '@state';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUsers = createSelector(
  selectUserState,
  (state: UserState) => state.users
);

export const selectUsersWithProfile = createSelector(
  selectUsers,
  (users: User[]) => users.filter((user) => user.profile)
);

export const selectUsersFetchedAt = createSelector(
  selectUserState,
  (state: UserState) => state.fetchedAt
);

export const selectCurrentUser = createSelector(
  selectUsers,
  selectRouteState,
  (users: User[], route: RouteState) => {
    return users.find((x) => x.id === route.params['userId']);
  }
);

export const selectUserById = (userId: string) =>
  createSelector(selectUsers, (users: User[]) =>
    users.find((user) => user.id === userId)
  );
