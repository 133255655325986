<mat-card>
  <mat-card-header>
    {{ documentType.id ? 'Update' : 'Create' }} Document Type
  </mat-card-header>

  <mat-card-content>
    @if ($documentTypeFetched | async) {
    <form [formGroup]="form" class="mt-4">
      <mat-form-field class="w-100">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" type="test" />
      </mat-form-field>

      <div class="d-flex justify-content-end">
        <mat-icon
          matTooltip="This document the document options when uploading this document type, IE: a National ID might have 2 options: 'ID Document' and 'Passport'"
        >
          info
        </mat-icon>
      </div>
      <mat-form-field class="w-100">
        <mat-label>Document Options</mat-label>
        <mat-chip-grid
          #chipGrid
          aria-label="Enter keywords"
          [formControl]="getFormControl('options')"
        >
          @for (option of options; track $index) {
          <mat-chip-row (removed)="onDocumentOptionRemoved(option)">
            {{option}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          }
        </mat-chip-grid>
        <input
          placeholder="Type a document option..."
          [matChipInputFor]="chipGrid"
          (matChipInputTokenEnd)="onDocumentOptionAdded($event)"
        />
      </mat-form-field>

      <div class="d-flex align-items-center">
        <mat-checkbox formControlName="expiryDateRequired">
          Expiry Date Must Be Captured With Document
        </mat-checkbox>
      </div>

      <div class="d-flex align-items-center">
        <mat-checkbox formControlName="idNumberRequired">
          ID Number Must be Captured With Document
        </mat-checkbox>
      </div>

      <div class="d-flex align-items-center">
        <mat-checkbox formControlName="required">
          This Document Type Must Be Uploaded
        </mat-checkbox>
      </div>

      @if (error) {
      <app-alert class="mt-3" type="error"> {{ error }} </app-alert>
      }

      <div class="d-flex align-items-center justify-content-end mt-3">
        @if (loading) {
        <mat-progress-spinner
          class="me-2"
          mode="indeterminate"
          color="primary"
          diameter="30"
        />
        }

        <div style="display: flex">
          @if (documentType.id) {
          <button
            mat-raised-button
            color="warn"
            class="px-4 me-3"
            [disabled]="loading"
            (click)="onDeleteDocumentType()"
          >
            Delete Document Type
          </button>
          }
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="px-4"
            [disabled]="loading"
            (click)="onFormSubmit()"
          >
            {{ documentType.id ? 'Update' : 'Create' }} Document Type
          </button>
        </div>
      </div>
    </form>
    } @if (!($documentTypeFetched | async)) {
    <div class="d-flex justify-content-center">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    }
  </mat-card-content>
</mat-card>
