<div class="mb-4">User Address Details</div>
<form (ngSubmit)="onAddressFormSubmit()" [formGroup]="addressForm">
  <mat-form-field class="w-100">
    <mat-label>Street</mat-label>
    <input matInput formControlName="street" type="text" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Street Number</mat-label>
    <input matInput formControlName="streetNumber" type="number" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Suburb</mat-label>
    <input matInput formControlName="suburb" type="text" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Province</mat-label>
    <input matInput formControlName="province" type="text" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Postal Code</mat-label>
    <input matInput formControlName="postalCode" type="number" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Country</mat-label>
    <input matInput formControlName="country" type="text" />
  </mat-form-field>

  <div class="d-flex align-items-center justify-content-end mt-3">
    @if (loading) {
    <mat-progress-spinner
      class="me-2"
      mode="indeterminate"
      color="primary"
      diameter="30"
    />
    }
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="px-4"
      [disabled]="loading"
    >
      Save User Address
    </button>
  </div>
</form>
