<mat-card>
  @if (!!($vehiclesFetchedAt | async)) {
  <mat-card-content>
    <table mat-table [dataSource]="($vehicles | async) ?? []">
      <ng-container matColumnDef="make">
        <th mat-header-cell *matHeaderCellDef>Make</th>
        <td mat-cell *matCellDef="let element">{{ element.make }}</td>
      </ng-container>

      <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef>Model</th>
        <td mat-cell *matCellDef="let element">{{ element.model }}</td>
      </ng-container>

      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef>Year</th>
        <td mat-cell *matCellDef="let element">{{ element.year }}</td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef>Color</th>
        <td mat-cell *matCellDef="let element">{{ element.color }}</td>
      </ng-container>

      <ng-container matColumnDef="vin">
        <th mat-header-cell *matHeaderCellDef>Vin Number</th>
        <td mat-cell *matCellDef="let element">{{ element.vin }}</td>
      </ng-container>

      <ng-container matColumnDef="registration">
        <th mat-header-cell *matHeaderCellDef>Registration Number</th>
        <td mat-cell *matCellDef="let element">{{ element.registration }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        (click)="navToVehicleDetails(row.id)"
        *matRowDef="let row; columns: columns;"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell p-2" [attr.colspan]="columns.length">
          No Motor Bikes
        </td>
      </tr>
    </table>
  </mat-card-content>
  } @else {
  <div class="my-3 d-flex justify-content-center">
    <mat-progress-spinner diameter="30" mode="indeterminate" color="primary">
    </mat-progress-spinner>
  </div>
  }
</mat-card>
