import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { VehicleService } from '@services';
import * as VehicleActions from './vehicle.actions';

@Injectable()
export class VehicleEffects {
  constructor(
    private actions$: Actions,
    private vehicleService: VehicleService
  ) {}

  fetchVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.fetchVehicles),
      switchMap(() =>
        from(this.vehicleService.get()).pipe(
          map((vehicles) => VehicleActions.fetchVehiclesSuccess({ vehicles })),
          catchError((error) =>
            of(VehicleActions.fetchVehiclesFailed({ error }))
          )
        )
      )
    )
  );

  fetchVehicleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.fetchVehicleById),
      switchMap(({ vehicleId }) =>
        from(this.vehicleService.getById(vehicleId)).pipe(
          map((vehicle) => VehicleActions.fetchVehicleByIdSuccess({ vehicle })),
          catchError((error) =>
            of(VehicleActions.fetchVehicleByIdFailed({ error }))
          )
        )
      )
    )
  );

  assignUserToVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.assignUserToVehicle),
      switchMap(({ vehicleId, userId }) =>
        from(this.vehicleService.assignUserToVehicle(vehicleId, userId)).pipe(
          map((vehicle) => VehicleActions.assignUserToVehicleSuccess({ vehicle })),
          catchError((error) =>
            of(VehicleActions.assignUserToVehicleFailed({ error }))
          )
        )
      )
    )
  );
}
