import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DocumentType } from '@models';
import { DocumentTypeState } from '@state';
import { RouteState, selectRouteState } from '@state/route';

export const selectDocumentTypeState =
  createFeatureSelector<DocumentTypeState>('documentType');

export const selectDocumentTypes = createSelector(
  selectDocumentTypeState,
  (state: DocumentTypeState) => {
    return state.documentTypes ?? [];
  }
);

export const selectCurrentDocumentType = createSelector(
  selectDocumentTypes,
  selectRouteState,
  (documentTypes: DocumentType[], route: RouteState) => {
    return documentTypes.find((x) => x.id == route.params['documentTypeId']);
  }
);

export const selectDocumentTypesFetchedAt = createSelector(
  selectDocumentTypeState,
  (state: DocumentTypeState) => state.fetchedAt
);
