import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthService } from '@services';

import * as CoreActions from '../core.actions';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.appInit),
      switchMap(() => {
        this.authService.init();
        const token = this.authService.getToken();
        if (token) {
          return of(AuthActions.loginSuccess({ token }));
        } else {
          return of(AuthActions.logoutSuccess());
        }
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(({ email, password }) =>
        from(this.authService.login(email, password)).pipe(
          map(({ token }) => {
            return AuthActions.loginSuccess({ token });
          }),
          catchError((error) => of(AuthActions.loginFailed({ error })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => {
        this.authService.logout();
        return of(AuthActions.logoutSuccess());
      })
    )
  );
}
