import { DocumentType } from '@models';

export interface DocumentTypeState {
  documentTypes: DocumentType[];
  fetchedAt: Date | null;
}

export const initialDocumentTypeState: DocumentTypeState = {
  documentTypes: [],
  fetchedAt: null,
};
