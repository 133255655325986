import { createReducer, on } from '@ngrx/store';

import { StateUtils } from '@state/state-utils';
import * as DocumentTypeActions from './document-type.actions';
import {
  DocumentTypeState,
  initialDocumentTypeState,
} from './document-type.state';

const documentTypeReducer = createReducer<DocumentTypeState>(
  initialDocumentTypeState,

  on(
    DocumentTypeActions.createDocumentTypeSuccess,
    (state, { documentType }) => ({
      ...state,
      documentTypes: StateUtils.combineState(state.documentTypes, documentType),
    })
  ),
  on(
    DocumentTypeActions.fetchDocumentTypesSuccess,
    (state, { documentTypes }) => ({
      ...state,
      documentTypes,
      fetchedAt: new Date(),
    })
  ),
  on(
    DocumentTypeActions.fetchDocumentTypeByIdSuccess,
    (state, { documentType }) => ({
      ...state,
      documentTypes: StateUtils.combineState(state.documentTypes, documentType),
      fetchedAt: new Date(),
    })
  ),
  on(
    DocumentTypeActions.updateDocumentTypeSuccess,
    (state, { documentType }) => ({
      ...state,
      documentTypes: StateUtils.combineState(state.documentTypes, documentType),
    })
  ),
  on(DocumentTypeActions.deleteDocumentTypeSuccess, (state, { id }) => ({
    ...state,
    documentTypes: state.documentTypes.filter((x) => x.id !== id),
  })),
  on(
    DocumentTypeActions.fetchDocumentTypesFailed,
    DocumentTypeActions.fetchDocumentTypeByIdFailed,
    (state, { error }) => ({
      ...state,
      fetchedAt: new Date(),
    })
  )
);

export { documentTypeReducer };
