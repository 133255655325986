import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { fetchDocumentTypes, selectDocumentTypes } from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './document-types.page.html',
  styleUrl: './document-types.page.scss',
})
export class DocumentTypesPage implements OnInit {
  columns: string[] = [
    'name',
    'idTypes',
    'expiryDateRequired',
    'idNumberRequired',
    'required',
  ];

  $documentTypes = this.store.select(selectDocumentTypes);

  constructor(private store: Store, private router: Router) {}

  public ngOnInit(): void {
    this.store.dispatch(fetchDocumentTypes());
  }

  public navDocumentType(id?: string): void {
    this.router.navigate([`/document-types/${id ? id : 'new'}`]);
  }
}
