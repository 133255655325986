import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UtilService } from '@services';

import { ActionsState } from './action.state';

export const selectActionState = createFeatureSelector<ActionsState>('action');

export const selectActions = createSelector(
  selectActionState,
  (state: ActionsState) => state.actions
);
export const selectActionsFetchedAt = createSelector(
  selectActionState,
  (state: ActionsState) => state.fetchedAt
);
export const selectUniqueLoginActions = createSelector(
  selectActionState,
  (state: ActionsState) => {
    let actions = state.actions;
    actions = actions.filter((action) => action.action === 'login');
    return Array.from(
      new Map(
        actions.map((x) => {
          return [
            `${x.performedBy?.id}-${UtilService.formatDate(
              new Date(x.createdAt!)
            )}`,
            x,
          ];
        })
      ).values()
    );
  }
);
