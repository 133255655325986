@let user = $user | async;

<mat-card>
  <mat-card-content>
    @if (user) {
    <div class="flex">
      <div class="left">
        <div>
          <div
            (click)="setTab('profile')"
            [class.active]="currentTab === 'profile'"
            class="tab"
          >
            Profile
          </div>
          <div
            (click)="setTab('address')"
            [class.active]="currentTab === 'address'"
            class="tab"
          >
            Address
          </div>
          <div
            (click)="setTab('documents')"
            [class.active]="currentTab === 'documents'"
            class="tab"
          >
            Documents
          </div>
        </div>

        <div class="pb-4 px-3 pe-4">
          <div class="status-row">
            <div>User Status:</div>
            <mat-icon [ngClass]="isUserApproved(user) ? 'green' : 'red'">
              {{isUserApproved(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>

          <div class="my-3">
            <hr />
          </div>

          <div class="status-row mb-2">
            <div>Profile</div>
            <mat-icon [ngClass]="isProfileApproved(user) ? 'green' : 'red'">
              {{isProfileApproved(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
          <div class="status-row mb-2">
            <div>Address</div>
            <mat-icon [ngClass]="isAddressApproved(user) ? 'green' : 'red'">
              {{isAddressApproved(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
          <div class="status-row">
            <div>Documents</div>
            <mat-icon [ngClass]="areDocumentsApproved(user) ? 'green' : 'red'">
              {{areDocumentsApproved(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
        </div>
      </div>

      @if (currentTab == 'profile') {
      <div class="right">
        <app-user-profile [user]="user" />
      </div>
      } @if (currentTab == 'address') {
      <div class="right">
        <app-user-address [user]="user" />
      </div>
      } @if (currentTab == 'documents') {
      <div class="right">
        <app-user-documents [user]="user!"> </app-user-documents>
      </div>
      }
    </div>
    } @if (!($usersFetched | async)) {
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    }
  </mat-card-content>
</mat-card>
