import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';

import { AuthService } from '@services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/auth/login']); // Redirect if not authorized
      return false;
    }
  }
}
