<mat-card>
  <div>
    <mat-card-header> Actions </mat-card-header>
  </div>

  @if (!!($actionsFetchedAt | async)) {
  <mat-card-content>
    <table mat-table [dataSource]="($actions | async) ?? []">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let element">
          {{ getUserDisplay(element.user) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="performedBy">
        <th mat-header-cell *matHeaderCellDef>Performed By</th>
        <td mat-cell *matCellDef="let element">
          {{ getUserDisplay(element.performedBy) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          {{ getActionName(element.action) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef>Document</th>
        <td mat-cell *matCellDef="let element">{{ element.document?.id ?? '-' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="columns.length">No actions</td>
      </tr>
    </table>
  </mat-card-content>
  } @else {
  <div class="my-3 d-flex justify-content-center">
    <mat-progress-spinner diameter="30" mode="indeterminate" color="primary">
    </mat-progress-spinner>
  </div>
  }
</mat-card>
