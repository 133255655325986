import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ActionService } from '@services';
import * as ActionActions from './action.actions';

@Injectable()
export class ActionEffects {
  constructor(
    private actions$: Actions,
    private actionService: ActionService
  ) {}

  fetchActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionActions.fetchActions),
      switchMap(() =>
        from(this.actionService.get()).pipe(
          map((actions) => ActionActions.fetchActionsSuccess({ actions })),
          catchError((error) => of(ActionActions.fetchActionsFailed({ error })))
        )
      )
    )
  );
}
