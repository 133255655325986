import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { UtilService } from '@services';

import { Address, User } from '@models';

@Component({
  selector: 'app-user-address',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatGridListModule,
  ],
  templateUrl: './user-address.component.html',
  styleUrl: './user-address.component.scss',
})
export class UserAddressComponent implements OnChanges {
  @Input() user: User | null = null;

  public loading: boolean = false;
  public error: string | null = '';

  addressForm = new FormGroup({
    street: new FormControl('', [Validators.required]),
    streetNumber: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
  });

  constructor(private store: Store, private utils: UtilService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user) {
      this.addressForm.patchValue({
        street: this.user?.address?.street!,
        streetNumber: this.user?.address?.streetNumber!,
        suburb: this.user?.address?.suburb!,
        province: this.user?.address?.province!,
        postalCode: this.user?.address?.postalCode!,
        country: this.user?.address?.country!,
      });
    }
  }

  public async onAddressFormSubmit() {
    if (!this.addressForm.valid) {
      this.error = 'Please complete all fields';
      return;
    }
    this.error = '';

    this.loading = true;
    this.addressForm.disable();

    try {
      // TODO: Update user address
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.addressForm.enable();
    this.loading = false;
  }

  public addressCompleted(address: Address): boolean {
    if (!address) return false;
    return !!(
      address.street &&
      address.streetNumber &&
      address.suburb &&
      address.province &&
      address.postalCode &&
      address.country
    );
  }
}
