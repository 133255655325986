import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService, UtilService } from '..';

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private utils: UtilService
  ) {}

  get<T>(url: string): Promise<T> {
    const headers = this.getAuthHeaders();
    const observable = this.http.get<T>(url, { headers });
    return this.utils.toPromise(observable);
  }

  post<T>(url: string, body: any): Promise<T> {
    const headers = this.getAuthHeaders();
    const observable = this.http.post<T>(url, body, { headers });
    return this.utils.toPromise(observable);
  }

  put<T>(url: string, body: any): Promise<T> {
    const headers = this.getAuthHeaders();
    const observable = this.http.put<T>(url, body, { headers });
    return this.utils.toPromise(observable);
  }

  delete<T>(url: string): Promise<T> {
    const headers = this.getAuthHeaders();
    const observable = this.http.delete<T>(url, { headers });
    return this.utils.toPromise(observable);
  }

  private getAuthHeaders(): HttpHeaders {
    const token = this.auth.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
  }
}
