import { Injectable } from '@angular/core';

import { Vehicle } from '@models';
import { environment } from 'src/environment/environment';
import { CrudService, IResponsePayload } from '../crud/crud.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class VehicleService extends CrudService<Vehicle> {
  constructor(http: HttpService) {
    super('vehicle', http);
  }

  async assignUserToVehicle(
    vehicleId: string,
    userId: string
  ): Promise<Vehicle> {
    const endpoint = `${environment.apiUrl}/admin/vehicle/assign-user`;
    const response = await this.http.post<IResponsePayload>(endpoint, {
      userId,
      vehicleId,
    });

    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  // override methods which are not available for this service

  override create(data: Partial<Vehicle>): Promise<Vehicle> {
    throw new Error('Method not available.');
  }
  override update(data: Partial<Vehicle>): Promise<Vehicle> {
    throw new Error('Method not available.');
  }
  override delete(id: string): Promise<string> {
    throw new Error('Method not available.');
  }
}
