import { environment } from '../../../environment/environment';

import { BaseModel } from '../../models/base.model';
import { HttpService } from '../http/http.service';

export interface IResponsePayload {
  success: boolean;
  message: string;
  data: any;
}

export abstract class CrudService<T extends BaseModel> {
  private url: string = '';

  constructor(private endpoint: string, protected http: HttpService) {
    this.url = `${environment.apiUrl}/${this.endpoint}`;
  }

  async get(): Promise<T[]> {
    const response: IResponsePayload = await this.http.get<IResponsePayload>(
      `${this.url}/all`
    );
    if (!response.success) {
      throw new Error(response.message);
    }
    return response.data;
  }

  async getById(id: string): Promise<T> {
    const response: IResponsePayload = await this.http.get<IResponsePayload>(
      `${this.url}/${id}`
    );
    if (!response.success) {
      throw new Error(response.message);
    }
    return response.data;
  }

  async create(item: T): Promise<T> {
    const response: IResponsePayload = await this.http.post<IResponsePayload>(
      this.url,
      item
    );
    if (!response.success) {
      throw new Error(response.message);
    }
    return response.data;
  }

  async update(item: T): Promise<T> {
    const response: IResponsePayload = await this.http.put<IResponsePayload>(
      `${this.url}/${item.id}`,
      item
    );
    if (!response.success) {
      throw new Error(response.message);
    }
    return response.data;
  }

  async delete(id: string): Promise<string> {
    const response: IResponsePayload = await this.http.delete(
      `${this.url}/${id}`
    );
    if (!response.success) {
      throw new Error(response.message);
    }
    return response.message;
  }
}
