<mat-card>
  <div style="display: flex; padding: 16px; position: relative">
    <mat-card-header> Document Types </mat-card-header>
    <button
      (click)="navDocumentType()"
      mat-raised-button
      color="primary"
      type="submit"
      class="px-4"
      style="position: absolute; right: 16px"
    >
      Add Document Type
    </button>
  </div>

  @if ($documentTypes) {
  <mat-card-content>
    <table mat-table [dataSource]="($documentTypes | async) ?? []">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="idTypes">
        <th mat-header-cell *matHeaderCellDef>Document Options</th>
        <td mat-cell *matCellDef="let element">
          @for (option of element.options; track $index) {
          <div>{{ option }}</div>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="expiryDateRequired">
        <th mat-header-cell *matHeaderCellDef>Capture Expiry Date</th>
        <td mat-cell *matCellDef="let element">
          @if (element.expiryDateRequired) {
          <mat-icon> check_circle</mat-icon>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="idNumberRequired">
        <th mat-header-cell *matHeaderCellDef>Capture Document ID</th>
        <td mat-cell *matCellDef="let element">
          @if (element.idNumberRequired) {
          <mat-icon> check_circle</mat-icon>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="required">
        <th mat-header-cell *matHeaderCellDef>Required</th>
        <td mat-cell *matCellDef="let element">
          @if (element.required) {
          <mat-icon> check_circle</mat-icon>
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns;"
        (click)="navDocumentType(row.id)"
      ></tr>
    </table>
  </mat-card-content>
  }
</mat-card>
