import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { selectToken } from '../../../state/auth';
import * as AuthActions from '../../../state/auth/auth.actions';

import { AlertComponent } from '../../../components/alert/alert.component';
import { UtilService } from '../../../services/util/util.service';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    AlertComponent,
  ],
  templateUrl: './login.page.html',
  styleUrl: './login.page.scss',
})
export class LoginPage implements OnInit, OnDestroy {
  loading = false;
  error: string | null = '';

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  private tokenSub?: Subscription;

  constructor(
    private router: Router,
    private utils: UtilService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.tokenSub = this.store.select(selectToken).subscribe((token) => {
      if (token) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.tokenSub) this.tokenSub.unsubscribe();
  }

  async onFormSubmit() {
    if (!this.form.valid) return;
    this.error = '';

    this.loading = true;
    this.form.disable();

    try {
      const loginDto = {
        email: this.form.value.email!,
        password: this.form.value.password!,
      };
      await this.utils.actionsToPromise(
        AuthActions.login(loginDto),
        AuthActions.loginSuccess,
        AuthActions.loginFailed
      );
    } catch (err: any) {
      console.error(err);
      this.error = err.error.message;
    }

    this.form.enable();
    this.loading = false;
  }
}
