import { Data, Params } from '@angular/router';
import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

const {
  selectQueryParams, // select the current route query params
  selectRouteParams, // select the current route params
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getRouterSelectors();

export interface RouteState {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

export const selectRouteState = createSelector(
  selectQueryParams,
  selectRouteParams,
  selectRouteData,
  selectUrl,
  (queryParams, params, data, url) => ({
    queryParams,
    params,
    data,
    url,
  })
);
