import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { UtilService } from '@services';

import { Profile, User } from '@models';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatGridListModule,
  ],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
})
export class UserProfileComponent implements OnChanges {
  @Input() user: User | null = null;
  profile: Profile | undefined;

  public loading: boolean = false;
  public error: string = '';

  form = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    idNumber: new FormControl('', [Validators.required]),
    idType: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    nationality: new FormControl('', [Validators.required]),
  });

  constructor(private store: Store, private utils: UtilService) {}

  async ngOnChanges(): Promise<void> {
    if (this.user) {
      this.profile = this.user.profile;
      this.form.patchValue({
        firstName: this.user.profile?.firstName,
        lastName: this.user.profile?.lastName,
        // mobile: this.user.profile?.mobile ?? '',
        // email: this.user.profile?.email,
        idNumber: this.user.profile?.idNumber,
        idType: this.user.profile?.idType,
        gender: this.user.profile?.gender,
        nationality: this.user.profile?.nationality,
      });
    }
  }

  public async onProfileFormSubmit() {
    if (!this.form.valid) return;
    this.error = '';

    this.loading = true;
    this.form.disable();

    try {
      // todo: update user profile
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.form.enable();
    this.loading = false;
  }
}
