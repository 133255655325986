import { User } from '@models';

export interface UserState {
  users: User[];
  fetchedAt: Date | null;
}

export const initialUserState: UserState = {
  users: [],
  fetchedAt: null,
};
