<mat-drawer-container>
  <mat-drawer
    #sidenav
    mode="side"
    opened
    class="side-menu background"
    opened
    [(opened)]="opened"
  >
    <div class="flex-vert">
      <div>
        <div class="logo">
          <img src="logo.png" alt="Logo" />
        </div>

        <mat-nav-list class="p-2">
          <ng-container *ngFor="let item of menuItems; trackBy: trackByLink">
            <a
              mat-list-item
              [routerLink]="item.link"
              (click)="setActiveItem(item)"
              [routerLinkActive]="['active']"
              [ngClass]="{ active: isActive(item.link) }"
              [routerLinkActiveOptions]="{ exact: false }"
              class="mb-2"
            >
              <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
              <div matListItemTitle>{{ item.title }}</div>
            </a>
          </ng-container>
        </mat-nav-list>
      </div>
      <div class="p-2 pb-3">
        <button mat-raised-button (click)="onLogoutClicked()" class="w-100">
          <mat-icon>exit_to_app</mat-icon>
          <div>Logout</div>
        </button>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content class="content">
    <mat-toolbar>
      <mat-icon class="pointer me-3" (click)="sidenav.toggle()">menu</mat-icon>
      <app-breadcrumbs></app-breadcrumbs>
    </mat-toolbar>

    <div class="p-3">
      <router-outlet />
    </div>
  </mat-drawer-content>
</mat-drawer-container>
