<div class="mb-4">User Documents</div>

@if (documents.length) {
<div class="d-flex justify-content-between align-items-center">
  <div><b>{{ docType.name }}</b></div>
  <div class="d-flex justify-content-center2">
    {{ documentIndex + 1 + "/" + documents.length }}
  </div>
</div>

<div class="d-flex justify-content-end mt-2">
  <mat-icon>{{ getStatusIcon() }}</mat-icon>
  <div class="ms-2">{{ documentStatus() }}</div>
</div>

<div class="d-flex justify-content-center align-items-center my-3">
  <button mat-icon-button class="me-3" (click)="previousDocument()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div class="document-holder">
    <img [src]="documentUrl" />
  </div>
  <button mat-icon-button class="ms-3" (click)="nextDocument()">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div class="row">
  @if (doc.documentOption) {
  <div class="col-6 d-flex mt-3">
    <mat-icon>calendar_today</mat-icon>
    <div class="ms-2">Document Option: {{ doc.documentOption }}</div>
  </div>
  }
  <!---->
  @if (docType.idNumberRequired) {
  <div class="col-6 d-flex mt-3">
    <mat-icon>person</mat-icon>
    <div class="ms-2">ID Number: {{ doc.idNumber }}</div>
  </div>
  }
  <!---->
  @if (docType.expiryDateRequired) {
  <div class="col-6 d-flex mt-3">
    <mat-icon>calendar_today</mat-icon>
    <div class="ms-2">Expiry Date: {{ doc.expiryDate }}</div>
  </div>
  }

  <div class="col-6 d-flex mt-3">
    <mat-icon>flag</mat-icon>
    <div class="ms-2">Country Of Issue: {{ doc.countryOfIssue }}</div>
  </div>
</div>

@if (doc.status === 'pending' && !loading) {
<div class="d-flex justify-content-end mt-4">
  <button
    (click)="onDeclineDocumentClicked()"
    mat-raised-button
    color="warn"
    class="me-3 px-4"
  >
    Decline
  </button>
  <button (click)="onApproveDocumentClicked()" mat-raised-button class="px-4">
    Approve
  </button>
</div>
} @if (loading) {
<div class="d-flex justify-content-end mt-4">
  <mat-progress-spinner mode="indeterminate" color="primary" diameter="30" />
</div>
}
<!---->
}

<!---->

@if (!documents.length) {
<div class="d-flex justify-content-center mt-4">
  <mat-icon>upload_file</mat-icon>
  <div>This user has not uploaded any documents</div>
</div>
}
