import { createReducer, on } from '@ngrx/store';

import { StateUtils } from '@state/state-utils';
import * as ActionActions from './action.actions';
import { ActionsState, initialActionState } from './action.state';

const actionReducer = createReducer<ActionsState>(
  initialActionState,

  on(ActionActions.fetchActionsSuccess, (state, { actions }) => ({
    ...state,
    fetchedAt: new Date(),
    actions: StateUtils.combineStateArr(state.actions, actions),
  })),
  on(ActionActions.fetchActionsFailed, (state) => ({
    ...state,
    fetchedAt: new Date(),
  }))
);

export { actionReducer };
