<form (ngSubmit)="onProfileFormSubmit()" [formGroup]="form">
  <mat-label>Profile Picture</mat-label>
  <div class="d-flex justify-content-center">
    @if (profile?.image) {
    <img class="profile-picture" [src]="profile?.image" alt="profile image" />
    } @else {
    <mat-icon class="profile-picture">person</mat-icon>
    }
  </div>

  <mat-form-field class="w-100">
    <mat-label>First Name</mat-label>
    <input matInput formControlName="firstName" type="text" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Last Name</mat-label>
    <input matInput formControlName="lastName" type="text" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>ID Number</mat-label>
    <input matInput formControlName="idNumber" type="number" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Gender</mat-label>
    <input matInput formControlName="gender" type="text" />
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Nationality</mat-label>
    <input matInput formControlName="nationality" type="text" />
  </mat-form-field>

  <div class="d-flex align-items-center justify-content-end mt-3">
    @if (loading) {
    <mat-progress-spinner
      class="me-2"
      mode="indeterminate"
      color="primary"
      diameter="30"
    />
    }
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="px-4"
      [disabled]="loading"
    >
      Save User Profile
    </button>
  </div>
</form>
