import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { Store } from '@ngrx/store';

import { ActionType, User } from '@models';
import { fetchActions, selectActions, selectActionsFetchedAt } from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './actions.page.html',
  styleUrl: './actions.page.scss',
})
export class ActionsPage implements OnInit {
  columns: string[] = ['date', 'user', 'performedBy', 'action', 'document'];

  $actions = this.store.select(selectActions);
  $actionsFetchedAt = this.store.select(selectActionsFetchedAt);

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.store.dispatch(fetchActions());
  }

  public getActionName(action: ActionType): string {
    if (action === ActionType.LOGIN) return 'Login';
    if (action === ActionType.APPROVE_DOCUMENT) return 'Approve Document';
    if (action === ActionType.DECLINE_DOCUMENT) return 'Decline Document';
    if (action === ActionType.ASSIGN_VEHICLE) return 'Assign Vehicle';

    return '';
  }

  public getUserDisplay(user: User): string {
    return user?.name || user?.email || 'Unknown User';
  }
}
