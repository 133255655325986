@let vehicle = $vehicle | async; @let users = ($users | async) || [];

<mat-card>
  <mat-card-content>
    @if (vehicle && users.length) {
    <table>
      <tr>
        <td><b>Make</b></td>
        <td>{{ vehicle.make }}</td>
      </tr>
      <tr>
        <td><b>Model</b></td>
        <td>{{ vehicle.model }}</td>
      </tr>
      <tr>
        <td><b>Year</b></td>
        <td>{{ vehicle.year }}</td>
      </tr>
      <tr>
        <td><b>Color</b></td>
        <td>{{ vehicle.color }}</td>
      </tr>
      <tr>
        <td><b>Vin</b></td>
        <td>{{ vehicle.vin }}</td>
      </tr>
      <tr>
        <td><b>Registration</b></td>
        <td>{{ vehicle.registration }}</td>
      </tr>
    </table>

    <hr class="my-4" />

    <div>
      <mat-form-field class="w-100">
        <mat-label>Assigned Driver</mat-label>
        <mat-select [(ngModel)]="userId" name="userId">
          @for (user of users; track user) {
          <mat-option [value]="user.id">
            {{ user.profile?.firstName }} {{ user.profile?.lastName }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <div class="d-flex justify-content-end align-items-center">
        @if (loading) {
        <mat-progress-spinner
          class="me-2"
          mode="indeterminate"
          color="primary"
          diameter="30"
        />
        }
        <button
          (click)="onAssignDriverClicked()"
          mat-raised-button
          color="primary"
          class="px-4"
          [disabled]="loading"
        >
          Save Motorbike Driver
        </button>
      </div>
    </div>

    } @if (!($vehiclesFetchedAt | async)) {
    <mat-spinner></mat-spinner>
    }
  </mat-card-content>
</mat-card>
