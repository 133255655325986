import { Injectable } from '@angular/core';

import { Action } from '@models';
import { CrudService } from '../crud/crud.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ActionService extends CrudService<Action> {
  constructor(http: HttpService) {
    super('admin/action', http);
  }

  // override methods which are not available for this service

  override create(data: Partial<Action>): Promise<Action> {
    throw new Error('Method not available.');
  }
  override update(data: Partial<Action>): Promise<Action> {
    throw new Error('Method not available.');
  }
  override delete(id: string): Promise<string> {
    throw new Error('Method not available.');
  }
}
