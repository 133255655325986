import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AlertComponent, DialogComponent } from '@components';
import { DocumentType } from '@models';
import { UtilService } from '@services';
import {
  createDocumentType,
  createDocumentTypeFailed,
  createDocumentTypeSuccess,
  deleteDocumentType,
  deleteDocumentTypeFailed,
  deleteDocumentTypeSuccess,
  fetchCurrentDocumentType,
  selectCurrentDocumentType,
  selectDocumentTypesFetchedAt,
  updateDocumentType,
  updateDocumentTypeFailed,
  updateDocumentTypeSuccess,
} from '@state';

@Component({
  selector: 'app-document-type',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    AlertComponent,
  ],
  templateUrl: './document-type.page.html',
  styleUrl: './document-type.page.scss',
})
export class DocumentTypePage implements OnInit, OnDestroy {
  readonly dialog = inject(MatDialog);

  public loading: boolean = false;
  public error: string | null = null;
  public options: string[] = [];
  public documentType: DocumentType = new DocumentType();
  public $documentTypeFetched = this.store.select(selectDocumentTypesFetchedAt);
  private $documentType = this.store.select(selectCurrentDocumentType);

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    options: new FormControl<string[]>([]),
    expiryDateRequired: new FormControl(false, [Validators.required]),
    idNumberRequired: new FormControl(false, [Validators.required]),
    required: new FormControl(false, [Validators.required]),
  });

  constructor(
    private store: Store,
    private router: Router,
    private utils: UtilService
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(fetchCurrentDocumentType());

    this.$documentType.subscribe((documentType) => {
      if (!documentType) return;
      this.documentType = documentType;
      this.populateDocumentTypeForm(documentType);
    });
  }

  private populateDocumentTypeForm(documentType: DocumentType): void {
    this.form.patchValue({
      name: documentType.name,
      options: documentType.options,
      expiryDateRequired: documentType.expiryDateRequired,
      idNumberRequired: documentType.idNumberRequired,
      required: documentType.required,
    });
    this.options = [...(documentType.options ?? [])];
  }

  ngOnDestroy(): void {}

  public getFormControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  public onDocumentOptionAdded(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) this.options.push(value);
    event.chipInput!.clear();
  }

  public onDocumentOptionRemoved(option: string): void {
    this.options = this.options.filter((x) => x !== option);
  }

  public async onFormSubmit(): Promise<void> {
    if (!this.form.valid) {
      this.error = 'Please fill in all required fields';
      return;
    }

    this.loading = true;

    try {
      const documentType: DocumentType = {
        ...this.documentType,
        name: this.getFormControl('name').value,
        options: this.getFormControl('options').value,
        expiryDateRequired: this.getFormControl('expiryDateRequired').value,
        idNumberRequired: this.getFormControl('idNumberRequired').value,
        required: this.getFormControl('required').value,
      };

      if (!this.documentType.id) {
        await this.createDocumentType(documentType);
      } else {
        await this.updateDocumentType(documentType);
      }

      this.dialog
        .open(DialogComponent, {
          data: {
            title: 'Success',
            message: 'Document Type saved successfully',
          },
        })
        .afterClosed()
        .subscribe(() => {
          this.router.navigate(['/document-types']);
        });
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }

  private async createDocumentType(documentType: DocumentType): Promise<void> {
    await this.utils.actionsToPromise(
      createDocumentType({ documentType }),
      createDocumentTypeSuccess,
      createDocumentTypeFailed
    );
  }

  private async updateDocumentType(documentType: DocumentType): Promise<void> {
    await this.utils.actionsToPromise(
      updateDocumentType({ documentType }),
      updateDocumentTypeSuccess,
      updateDocumentTypeFailed
    );
  }

  public onDeleteDocumentType(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete Document Type',
        message: 'Are you sure you want to delete this document type?',
        cancel: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.deleteDocumentType(this.documentType);
    });
  }

  private async deleteDocumentType(documentType: DocumentType): Promise<void> {
    this.loading = true;

    try {
      await this.utils.actionsToPromise(
        deleteDocumentType({ id: documentType?.id! }),
        deleteDocumentTypeSuccess,
        deleteDocumentTypeFailed
      );

      this.router.navigate(['/document-types']);
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }
}
