import { BaseModel } from './base.model';

export class DocumentType extends BaseModel {
  name: string = '';
  options: string[] = [];
  expiryDateRequired: boolean = false;
  idNumberRequired: boolean = false;
  required: boolean = false;

  constructor(data?: Partial<DocumentType>) {
    super();
    Object.assign(this, data);
  }
}
