import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UtilService } from '@services';
import { environment } from '../../../environment/environment';

const TOKEN_KEY = 'RW_TOKEN';
interface LoginRes {
  token: string;
}
interface AuthRes {
  data: {
    token: string;
  };
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private baseAuthUrl = environment.apiUrl + '/auth';
  private token: string | null = null;

  constructor(private http: HttpClient, private utils: UtilService) {}

  public init(): void {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token && !this.utils.isJwtTokenExpired(token)) {
      this.token = token;
    }
  }

  public async login(email: string, password: string): Promise<LoginRes> {
    const body = { email, password };
    const endpoint = `${this.baseAuthUrl}/login`;
    const observable = this.http.post<AuthRes>(endpoint, body);
    const response = await this.utils.toPromise(observable);

    this.token = response.data.token;
    localStorage.setItem(TOKEN_KEY, this.token);

    return { token: this.token };
  }

  public logout(): void {
    this.token = null;
    localStorage.removeItem(TOKEN_KEY);
  }

  public isAuthenticated(): boolean {
    this.validateTokenIsNotExpired();
    return !!this.token;
  }

  public getToken(): string | null {
    this.validateTokenIsNotExpired();
    return this.token;
  }

  private validateTokenIsNotExpired(): void {
    if (!this.token) return;
    if (this.utils.isJwtTokenExpired(this.token)) {
      this.logout();
    }
  }
}
