<div class="d-flex">
  <mat-card class="w-100 me-2">
    <mat-card-content>
      <app-sign-ups [users]="$users | async"> </app-sign-ups>
    </mat-card-content>
  </mat-card>

  <mat-card class="w-100 ms-2">
    <mat-card-content>
      <app-active-users [actions]="$actions | async"></app-active-users>
    </mat-card-content>
  </mat-card>
</div>
