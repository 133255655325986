import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

import { ActiveUsersComponent, SignUpsComponent } from '@components';

import { Store } from '@ngrx/store';
import {
  fetchActions,
  fetchUsers,
  selectUniqueLoginActions,
  selectUsers,
} from '@state';

import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    SignUpsComponent,
    ActiveUsersComponent,
  ],
  templateUrl: './dashboard.page.html',
  styleUrl: './dashboard.page.scss',
})
export class DashboardPage implements OnInit {
  public $users = this.store.select(selectUsers);
  public $actions = this.store.select(selectUniqueLoginActions);

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(fetchUsers());
    this.store.dispatch(fetchActions());
  }
}
