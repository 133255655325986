import { createAction, props } from '@ngrx/store';

import { DocumentType } from '@models';

// Create Document Type:
export const createDocumentType = createAction(
  '[DocumentType] Create Initiated',
  props<{ documentType: DocumentType }>()
);
export const createDocumentTypeSuccess = createAction(
  '[DocumentType] Create Success',
  props<{ documentType: DocumentType }>()
);
export const createDocumentTypeFailed = createAction(
  '[DocumentType] Create Failed',
  props<{ error: Error }>()
);

// Fetch Document Types:
export const fetchDocumentTypes = createAction(
  '[DocumentType] Fetch Initiated'
);
export const fetchDocumentTypesSuccess = createAction(
  '[DocumentType] Fetch Success',
  props<{ documentTypes: DocumentType[] }>()
);
export const fetchDocumentTypesFailed = createAction(
  '[DocumentType] Fetch Failed',
  props<{ error: Error }>()
);

// Fetch Document Type By Id:
export const fetchDocumentTypeById = createAction(
  '[DocumentType] Fetch By Id Initiated',
  props<{ id: string }>()
);
export const fetchCurrentDocumentType = createAction(
  '[DocumentType] Fetch Current By Id Initiated'
);
export const fetchDocumentTypeByIdSuccess = createAction(
  '[DocumentType] Fetch By Id  Success',
  props<{ documentType: DocumentType | null }>()
);
export const fetchDocumentTypeByIdFailed = createAction(
  '[DocumentType] Fetch By Id  Failed',
  props<{ error: Error }>()
);

// Update Document Type:
export const updateDocumentType = createAction(
  '[DocumentType] Update Initiated',
  props<{ documentType: DocumentType }>()
);
export const updateDocumentTypeSuccess = createAction(
  '[DocumentType] Update Success',
  props<{
    documentType: DocumentType;
  }>()
);
export const updateDocumentTypeFailed = createAction(
  '[DocumentType] Update Failed',
  props<{ error: Error }>()
);

// Delete Document Type:
export const deleteDocumentType = createAction(
  '[DocumentType] Delete Initiated',
  props<{ id: string }>()
);
export const deleteDocumentTypeSuccess = createAction(
  '[DocumentType] Delete Success',
  props<{ id: string }>()
);
export const deleteDocumentTypeFailed = createAction(
  '[DocumentType] Delete Failed',
  props<{ error: Error }>()
);
