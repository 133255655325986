import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';

import { MatButtonModule } from '@angular/material/button';
import { DocumentType, User } from '@models';
import { UserService } from '@services';
import {
  fetchDocumentTypes,
  fetchUsers,
  selectDocumentTypes,
  selectUsers,
} from '@state';

interface Status {
  value: string;
  viewValue: string;
}

@Component({
  standalone: true,
  imports: [
    RouterOutlet,
    MatCardModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatIconModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './users.page.html',
  styleUrl: './users.page.scss',
})
export class UsersPage implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  status: Status[] = [
    { value: 'approved', viewValue: 'Approved' },
    { value: 'rejected', viewValue: 'Rejected' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'incomplete', viewValue: 'Incomplete' },
  ];

  columns: string[] = [
    'id',
    'email',
    'createdAt',
    'updatedAt',
    'role',
    'status',
  ];

  search: string = '';
  selectedStatus: string = '';
  dataSource = new MatTableDataSource<User>();
  private _liveAnnouncer = inject(LiveAnnouncer);
  private documentTypes: DocumentType[] = [];

  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
    this.store.select(selectUsers).subscribe((users) => {
      this.dataSource.data = users;
    });

    this.store.select(selectDocumentTypes).subscribe((documentTypes) => {
      this.documentTypes = documentTypes;
    });

    this.store.dispatch(fetchUsers());
    this.store.dispatch(fetchDocumentTypes());
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public filterUsers() {
    this.search = this.search.toLowerCase();
    this.store
      .select(selectUsers)
      .pipe(
        map((users) =>
          users.filter(
            (user) =>
              user.id?.toLowerCase().includes(this.search) ||
              user.email?.toLowerCase().includes(this.search) ||
              user.role.toLowerCase().includes(this.search)
          )
        )
      )
      .subscribe((filteredUsers) => (this.dataSource.data = filteredUsers));
  }

  public filterByStatus() {
    this.store
      .select(selectUsers)
      .pipe(
        map((users) =>
          users.filter(
            (user) => this.getUserStatus(user) === this.selectedStatus
          )
        )
      )
      .subscribe((filteredUsers) => (this.dataSource.data = filteredUsers));
  }

  public getUserStatus(user: User): string {
    return this.isUserApproved(user) ? 'approved' : 'incomplete';
  }

  public clearFilter() {
    this.search = '';
    this.selectedStatus = '';
    this.store.select(selectUsers).subscribe((users) => {
      this.dataSource.data = users;
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  navToUserDetails(userId: string) {
    this.router.navigate(['/users', userId]);
  }

  public isUserApproved(user: User): boolean {
    return UserService.isUserApproved(user, this.documentTypes);
  }
}
