import { createAction, props } from '@ngrx/store';

import { Vehicle } from '@models';

// fetch vehicles
export const fetchVehicles = createAction('[Vehicle] Fetch Initiated');
export const fetchVehiclesSuccess = createAction(
  '[Vehicle] Fetch Success',
  props<{ vehicles: Vehicle[] }>()
);
export const fetchVehiclesFailed = createAction(
  '[Vehicle] Fetch Failed',
  props<{ error: Error }>()
);

// fetch vehicle by id
export const fetchVehicleById = createAction(
  '[Vehicle] Fetch By Id Initiated',
  props<{ vehicleId: string }>()
);
export const fetchVehicleByIdSuccess = createAction(
  '[Vehicle] Fetch By Id Success',
  props<{ vehicle: Vehicle }>()
);
export const fetchVehicleByIdFailed = createAction(
  '[Vehicle] Fetch By Id Failed',
  props<{ error: Error }>()
);

// assign user to vehicle
export const assignUserToVehicle = createAction(
  '[Vehicle] Assign User To Vehicle',
  props<{ vehicleId: string; userId: string }>()
);
export const assignUserToVehicleSuccess = createAction(
  '[Vehicle] Assign User To Vehicle Success',
  props<{ vehicle: Vehicle }>()
);
export const assignUserToVehicleFailed = createAction(
  '[Vehicle] Assign User To Vehicle Failed',
  props<{ error: Error }>()
);
