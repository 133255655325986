import { Injectable } from '@angular/core';

import { DocumentStatus } from '@enums';
import { DocumentType, User } from '@models';
import { CrudService, HttpService } from '@services';

@Injectable({ providedIn: 'root' })
export class UserService extends CrudService<User> {
  constructor(http: HttpService) {
    super('user', http);
  }

  // public userCompleted(user: User): boolean {
  //   if (!user) return false;
  //   return !!(user.role && user.email);
  // }

  public static isUserApproved(
    user: User,
    documentTypes: DocumentType[]
  ): boolean {
    return (
      this.isUserProfileApproved(user) &&
      this.isUserAddressApproved(user) &&
      this.areUserDocumentsApproved(user, documentTypes)
    );
  }

  public static isUserProfileApproved(user: User): boolean {
    const profile = user.profile;
    if (!profile) return false;
    return !!(
      profile.firstName &&
      profile.lastName &&
      profile.idNumber &&
      profile.idType &&
      profile.gender &&
      profile.nationality
    );
  }

  public static isUserAddressApproved(user: User): boolean {
    const address = user.address;
    if (!address) return false;
    return !!(
      address.street &&
      address.streetNumber &&
      address.suburb &&
      address.province &&
      address.postalCode &&
      address.country
    );
  }

  public static areUserDocumentsApproved(
    user: User,
    documentTypes: DocumentType[]
  ): boolean {
    const documents = user.documents;
    if (!documents || !documents.length) return false;

    const requiredDocTypes = documentTypes.filter((x) => x.required);
    for (const docType of requiredDocTypes) {
      const doc = documents.find((x) => x.documentType.id === docType.id);
      if (!doc || doc.status != DocumentStatus.APPROVED) return false;
    }

    return true;
  }
}
