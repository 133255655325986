import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

type AlertType = 'success' | 'info' | 'error' | 'warning';

@Component({
  standalone: true,
  selector: 'app-alert',
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  @Input() type: AlertType = 'success';
  @Input() class: string = '';

  @Output() close = new EventEmitter<void>();

  onCloseClicked() {
    this.close.emit();
  }
}
