import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Vehicle } from '@models';
import { VehicleState } from './vehicle.state';

export const selectVehicleState =
  createFeatureSelector<VehicleState>('vehicle');

export const selectVehicles = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.vehicles
);

export const selectVehicleById = (vehicleId: string) =>
  createSelector(selectVehicles, (vehicles: Vehicle[]) =>
    vehicles.find((vehicle) => vehicle.id === vehicleId)
  );

export const selectVehiclesFetchedAt = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.fetchedAt
);
