<nav aria-label="breadcrumb">
  <div class="breadcrumb">
    @for (breadcrumb of breadcrumbs; track $index; let last = $last) {
    <div class="breadcrumb-item" [ngClass]="{ active: last }">
      @if(!last) {
      <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
      <span class="mx-2">></span>
      } @if(last) {
      <span>{{ breadcrumb.label }}</span>
      }
    </div>
    }
  </div>
</nav>
