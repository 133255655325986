import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[Auth] Login Initiated',
  props<{ email: string; password: string }>()
);
export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ token: string }>()
);
export const loginFailed = createAction(
  '[Auth] Login Failed',
  props<{ error: Error }>()
);

// update user
export const logout = createAction('[Auth] Logout Initiated');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const logoutFailed = createAction(
  '[Auth] Logout Failed',
  props<{ error: Error }>()
);
