import { createReducer, on } from '@ngrx/store';

import { StateUtils } from '@state/state-utils';
import * as VehicleActions from './vehicle.actions';
import { VehicleState, initialVehicleState } from './vehicle.state';

const vehicleReducer = createReducer<VehicleState>(
  initialVehicleState,

  on(VehicleActions.fetchVehiclesSuccess, (state, { vehicles }) => ({
    ...state,
    fetchedAt: new Date(),
    vehicles: StateUtils.combineStateArr(state.vehicles, vehicles),
  })),
  on(VehicleActions.fetchVehiclesFailed, (state) => ({
    ...state,
    fetchedAt: new Date(),
  })),
  on(VehicleActions.fetchVehicleByIdSuccess, (state, { vehicle }) => ({
    ...state,
    fetchedAt: new Date(),
    vehicles: StateUtils.combineState(state.vehicles, vehicle),
  })),
  on(VehicleActions.fetchVehicleByIdFailed, (state) => ({
    ...state,
    fetchedAt: new Date(),
  }))
);

export { vehicleReducer };
