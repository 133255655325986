import { Vehicle } from '@models';

export interface VehicleState {
  vehicles: Vehicle[];
  fetchedAt: Date | null;
}

export const initialVehicleState: VehicleState = {
  vehicles: [],
  fetchedAt: null,
};
