import { ActionReducerMap } from '@ngrx/store';
import { State } from '@state';

import { actionReducer } from './action/action.reducer';
import { authReducer } from './auth/auth.reducer';
import { documentTypeReducer } from './document-type/document-type.reducer';
import { userReducer } from './user/user.reducer';
import { vehicleReducer } from './vehicle';

export const reducers: ActionReducerMap<State> = {
  auth: authReducer,
  user: userReducer,
  documentType: documentTypeReducer,
  action: actionReducer,
  vehicle: vehicleReducer,
};
