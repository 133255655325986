import { BaseModel } from '../models/base.model';

export class StateUtils {
  static combineStateArr<T extends BaseModel>(
    existingItems: T[],
    newItems: T[]
  ): T[] {
    if (!newItems || !newItems.length) return [...existingItems];

    let result: T[] = [];
    let newItemMap: { [id: string]: T } = {};
    let existingItemMap: { [id: string]: boolean } = {};

    for (const newItem of newItems) {
      if (!newItem) continue;
      newItemMap[newItem.id ?? ''] = newItem;
    }

    for (const existingItem of existingItems) {
      const newItem = newItemMap[existingItem.id ?? ''];
      if (newItem) {
        result.push(newItem);
      } else {
        result.push(existingItem);
      }
      existingItemMap[existingItem.id ?? ''] = true;
    }

    for (const newItem of newItems) {
      if (!existingItemMap[newItem.id ?? '']) result.push(newItem);
    }

    return result;
  }

  static combineState<T extends BaseModel>(
    existingItems: T[],
    newItem: T | null | undefined
  ): T[] {
    if (!newItem) return [...existingItems];

    existingItems = [...existingItems];
    if (existingItems.find((x) => x.id === newItem.id)) {
      existingItems = existingItems.map((x) =>
        x.id === newItem.id ? newItem : x
      );
    } else {
      existingItems.push(newItem);
    }

    return existingItems;
  }
}
