import { createReducer, on } from '@ngrx/store';

import { Document, User } from '@models';
import { StateUtils } from '@state/state-utils';
import * as UserActions from './user.actions';
import { initialUserState, UserState } from './user.state';

const isUsersDocument = (user: User, document: Document): boolean => {
  const doc = (document as any) ?? {};
  const userId = doc.userId || doc.user?.id || doc.user;
  return user.id == userId;
};

const getUserWithUpdatedDocumentStatus = (
  user: User,
  document: Document
): User => {
  return {
    ...user,
    documents: user.documents?.map((doc) => {
      if (doc.id === document.id) {
        return { ...doc, status: document.status };
      } else {
        return doc;
      }
    }),
  };
};

const userReducer = createReducer<UserState>(
  initialUserState,

  on(UserActions.fetchUsersSuccess, (state, { users }) => ({
    ...state,
    users,
    fetchedAt: new Date(),
  })),
  on(UserActions.fetchUsersFailed, (state, { error }) => ({
    ...state,
    fetchedAt: new Date(),
  })),

  on(UserActions.fetchUserByIdSuccess, (state, { user }) => ({
    ...state,
    users: StateUtils.combineState(state.users, user),
    fetchedAt: new Date(),
  })),
  on(UserActions.fetchUserByIdFailed, (state, { error }) => ({
    ...state,
    fetchedAt: new Date(),
  })),

  on(UserActions.updateUserSuccess, (state, { user }) => ({
    ...state,
    users: StateUtils.combineState(state.users, user),
  })),

  on(
    UserActions.approveUserDocumentSuccess,
    UserActions.declineUserDocumentSuccess,
    (state, { document }) => ({
      ...state,
      users: state.users.map((user) => {
        if (isUsersDocument(user, document)) {
          return getUserWithUpdatedDocumentStatus(user, document);
        }
        return user;
      }),
    })
  )
);

export { userReducer };
