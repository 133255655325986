import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { DocumentService, UserService } from '@services';
import { selectRouteState } from '@state/route';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private userService: UserService,
    private documentService: DocumentService
  ) {}

  fetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchUsers),
      switchMap(() =>
        from(this.userService.get()).pipe(
          map((users) => UserActions.fetchUsersSuccess({ users })),
          catchError((error) => of(UserActions.fetchUsersFailed({ error })))
        )
      )
    )
  );

  fetchUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchUserById),
      switchMap(({ userId }) =>
        from(this.userService.getById(userId)).pipe(
          map((user) => UserActions.fetchUserByIdSuccess({ user })),
          catchError((error) => of(UserActions.fetchUserByIdFailed({ error })))
        )
      )
    )
  );

  fetchCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchCurrentUser),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const userId = route.params['userId'];
        return of(UserActions.fetchUserById({ userId }));
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      switchMap(({ user }) =>
        from(this.userService.update(user)).pipe(
          map((user) => UserActions.updateUserSuccess({ user })),
          catchError((error) => of(UserActions.updateUserFailed({ error })))
        )
      )
    )
  );

  approveUserDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.approveUserDocument),
      switchMap(({ document }) =>
        from(this.documentService.approveDocument(document)).pipe(
          map((document) =>
            UserActions.approveUserDocumentSuccess({ document })
          ),
          catchError((error) =>
            of(UserActions.approveUserDocumentFailed({ error }))
          )
        )
      )
    )
  );

  declineUserDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.declineUserDocument),
      switchMap(({ document }) =>
        from(this.documentService.declineDocument(document)).pipe(
          map((document) =>
            UserActions.declineUserDocumentSuccess({ document })
          ),
          catchError((error) =>
            of(UserActions.declineUserDocumentFailed({ error }))
          )
        )
      )
    )
  );
}
