import { Action } from '@models';

export interface ActionsState {
  actions: Action[];
  fetchedAt: Date | null;
}

export const initialActionState: ActionsState = {
  actions: [],
  fetchedAt: null,
};
