import { createReducer, on } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { AuthState, initialAuthState } from './auth.state';

const authReducer = createReducer<AuthState>(
  initialAuthState,

  on(AuthActions.loginSuccess, (state, { token }) => ({
    ...state,
    token,
  })),

  on(AuthActions.logoutSuccess, (state) => ({
    ...state,
    token: '',
  }))
);

export { authReducer };

