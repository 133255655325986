import { Injectable } from '@angular/core';

import { DocumentType } from '@models';
import { CrudService, HttpService } from '@services';

@Injectable({ providedIn: 'root' })
export class DocumentTypeService extends CrudService<DocumentType> {
  constructor(http: HttpService) {
    super('document-type', http);
  }
}
